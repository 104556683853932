import * as React from 'react';
import { createSSRStore, toSerializedDataProps } from '@awning/archie';
import withSession from '@/src/auth/withSession';
import { AuthServerSidePropsContext, TBasePageProps, TGetServerSideProps } from 'types';
import { TUser, userStoreCreator } from '@/src/shared/userStore';
import ServerAxios from '@/src/ServerAxios';
import {
  EFilterPages,
  FILTER_DEFAULTS,
  TTopMarketFilterState,
  filterStoreCreator,
  toServerQueryState,
  useFilterStore,
} from '@/src/Filters';
import {
  Navigation,
  TTopMarketsStore,
  TopMarkets,
  topMarketsStoreCreator,
} from '@/src/top-airbnb-markets';
import type { AxiosResponse } from 'axios';
import Head from 'next/head';
import {
  getLongStateName,
  isMultipleStatesSelected,
  isSingleStateSelected,
} from '@/src/top-airbnb-markets/utils';
import { SignupBanner } from '@/src/SignupBanner/SignupBanner';
import { SignupModal } from '@/src/SignupModal';

type Props = TBasePageProps;

const TopMarketsHeadTags = React.memo(() => {
  const statesSelected = useFilterStore<TTopMarketFilterState>(
    state => state?.filterState?.states?.values
  );

  const getTitleAndDescription = () => {
    if (isMultipleStatesSelected(statesSelected)) {
      const allYourStatesAreBelongToUs = statesSelected.join(', ');
      return {
        tabTitle: `Top Airbnb Markets in ${allYourStatesAreBelongToUs} | Awning`,
        meta: {
          title: `Best Places to Airbnb in ${allYourStatesAreBelongToUs}`,
          description: `Awning's ranking of the best places for Airbnb investing in ${allYourStatesAreBelongToUs}`,
        },
      };
    }
    if (isSingleStateSelected(statesSelected)) {
      const shortStateName = statesSelected?.[0].toUpperCase();
      const longStateName = getLongStateName(shortStateName);
      return {
        tabTitle: `Top Airbnb Markets in ${shortStateName} | Awning`,
        meta: {
          title: `Best Places to Airbnb in ${longStateName}: Invest and Host in Top Cities`,
          description: `Uncover the best cities to invest in Airbnb properties across ${longStateName}. Analyze trends, demand, and ROI potential to maximize short-term rental profits.`,
        },
      };
    }

    return {
      tabTitle: 'Top Airbnb Markets | Awning',
      meta: {
        title: `Top Airbnb Markets in the Unites States | Awning`,
        description: `Awning's rankings of the top Airbnb markets in the United States`,
      },
    };
  };

  const {
    tabTitle,
    meta: { title, description },
  } = getTitleAndDescription();

  return (
    <Head>
      <title>{tabTitle ?? title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@tryawning" />
      <meta name="twitter:creator" content="@tryawning" />
      <meta
        name="twitter:image"
        content={`${process.env.NEXT_PUBLIC_BASE_PATH}/twitter-preview.jpg`}
      />

      <meta property="og:title" content={title} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={`${process.env.NEXT_PUBLIC_BASE_PATH}/facebook-preview.jpg`}
      />
    </Head>
  );
});

const TopMarketsContainer: React.FC<React.PropsWithChildren<Props>> = () => {
  return (
    <>
      <TopMarketsHeadTags />
      <Navigation render={() => <SignupBanner />} />
      <TopMarkets />

      <SignupModal />
    </>
  );
};

const getTopMarkets = async (
  filterQueryState: any,
  token: string
): Promise<
  AxiosResponse<{
    data: TTopMarketsStore['topMarkets'];
  }>
> => {
  const headers = token ? { headers: { 'Authorization': `Bearer ${token}` } } : {};
  return ServerAxios.get(`${process.env.NEXT_PUBLIC_AWNING_API_URL}/top_markets`, {
    params: {
      ...headers,
      filterQueryState,
    },
  });
};

const getUser = async (token: string, userProfileId: string) => {
  return ServerAxios.get(`${process.env.NEXT_PUBLIC_AWNING_API_URL}/users/${userProfileId}/`, {
    headers: { 'Authorization': `Bearer ${token}` },
  });
};

export const getServerSideProps = withSession(
  async ({ req, query }: AuthServerSidePropsContext): TGetServerSideProps<Props> => {
    const user = req.session.user;

    let userData: TUser;
    if (user !== undefined) {
      const userDataResponse = await getUser(user.token, user.id);
      userData = userDataResponse?.data;
    }
    const _userStore = createSSRStore(userStoreCreator, api => api.setState({ user: userData }));

    const { q, filterState = '{}' } = query as {
      q: string;
      filterState: any;
    };

    const activePage = EFilterPages.TOP_MARKETS;

    const _filterStore = createSSRStore(filterStoreCreator, api => {
      const state = filterState ? JSON.parse(filterState as string) : undefined;
      api.setState({ activePage: activePage, filterState: FILTER_DEFAULTS[activePage].FILTERS });
      api.getState().applyChangeSet(state);
    });

    let filterQueryState = toServerQueryState(
      _filterStore.getState()?.filterState,
      FILTER_DEFAULTS[activePage].INACTIVE_FILTERS,
      FILTER_DEFAULTS[activePage].TRANSFORMS
    );

    let topMarkets: TTopMarketsStore['topMarkets'] =
      (await getTopMarkets(filterQueryState, user?.token))?.data?.data ?? [];

    const _topMarketsStore = createSSRStore(topMarketsStoreCreator, api =>
      api.setState({ topMarkets, q })
    );

    return {
      props: {
        __storeData: toSerializedDataProps([_userStore, _topMarketsStore, _filterStore]),
      },
    };
  }
);

export default TopMarketsContainer;
